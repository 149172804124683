import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import { ForecastParameterType } from 'shared/lib/types';
import { processForecastParameters } from 'forecasting/pages/forecasting/utils/forecastUtils';

import { useDefaultParametersQuery } from 'shared/api/rtkq/forecasts';

function useForecastPatientsRows() {
  const forecast = useSelector(selectForecast);
  const { currentData: defaultParameters } = useDefaultParametersQuery(
    forecast.trace_id,
  );

  return useMemo(() => {
    const screenedRows = processForecastParameters(
      defaultParameters?.[ForecastParameterType.PATIENTS_SCREENED_RATE],
      'Patients screened',
    );

    const enrolledRows = processForecastParameters(
      defaultParameters?.[ForecastParameterType.PATIENTS_ENROLLED_RATE],
      'Patients enrolled',
    );

    const completedRows = processForecastParameters(
      defaultParameters?.[ForecastParameterType.PATIENTS_COMPLETED_RATE],
      'Patients completed',
    );

    return [...screenedRows, ...enrolledRows, ...completedRows];
  }, [defaultParameters]);
}

export default useForecastPatientsRows;
