import { useMemo } from 'react';

import type { CondorGridOptions } from 'shared/components/ag-grid/types';

export default function useForecastedCroExpenseGridOptions(): CondorGridOptions {
  return useMemo(
    () => ({
      groupDisplayType: 'groupRows',
      getRowId: 'id',
      getRowStyle: 'highlightRow',
      suppressStickyTotalRow: 'group',
      groupTotalRow: 'bottom',
      grandTotalRow: 'bottom',
      suppressAggFuncInHeader: true,
    }),
    [],
  );
}
