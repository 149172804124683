import { useSelector } from 'react-redux';

import NavigationTabs from 'shared/components/tabs/NavigationTabs';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import {
  getForecastAssumptionsTab,
  getForecastDetailTab,
  getForecastParametersTab,
  getForecastSummaryTab,
  getForecastingTabWithContractContainer,
} from 'routes';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';

import { useGetContractContainersWithFiltersQuery } from 'shared/api/rtkq/contractcontainers';

function ForecastingTabs() {
  const forecast = useSelector(selectForecast);
  const expensesTabEnabled = useFeatureFlag('forecasting_expenses');
  const { data: contractContainers } = useGetContractContainersWithFiltersQuery(
    { trial: forecast.trial, vendor_type: 'CRO' },
  );

  const forecastingTabs = [
    { key: getForecastSummaryTab(forecast.trace_id), label: 'Summary' },
    { key: getForecastDetailTab(forecast.trace_id), label: 'Detail' },
    {
      key: getForecastAssumptionsTab(forecast.trace_id),
      label: 'Assumptions',
    },
    {
      key: getForecastParametersTab(forecast.trace_id),
      label: 'Parameters',
    },
    ...(expensesTabEnabled
      ? [
          {
            key: getForecastingTabWithContractContainer(
              forecast.trace_id,
              'expenses',
              contractContainers?.[0].trace_id,
            ),
            label: 'Expenses',
          },
        ]
      : []),
  ];

  return <NavigationTabs tabs={forecastingTabs} />;
}

export default ForecastingTabs;
