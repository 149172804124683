import { useMemo } from 'react';

import type { ForecastedGridsResponse } from 'shared/lib/types';

export default function useForecastedInvestigatorFeesRows(
  generatedForecast: ForecastedGridsResponse | undefined,
  contractContainerSlug: string | undefined,
  forecastedMonths: string[],
) {
  return useMemo(() => {
    if (!contractContainerSlug) return undefined;
    const investigatorLineItems =
      generatedForecast?.line_items_forecasting[contractContainerSlug]
        ?.investigator_line_items;
    if (!investigatorLineItems) return undefined;

    const patientCosts: Record<string, number[] | undefined> = {};
    const adminCosts: Record<string, number[] | undefined> = {};

    for (const { patient_costs, admin_costs } of investigatorLineItems) {
      for (const [region, cost] of Object.entries(patient_costs)) {
        patientCosts[region] ||= [];
        patientCosts[region]?.push(cost);
      }
      for (const [_assumptionGroup, regionCosts] of Object.entries(
        admin_costs,
      )) {
        const totalCosts: Record<string, number> = {};
        for (const [region, cost] of Object.entries(regionCosts)) {
          totalCosts[region] ||= 0;
          totalCosts[region] += cost;
        }
        for (const [region, cost] of Object.entries(totalCosts)) {
          adminCosts[region] ||= [];
          adminCosts[region]?.push(cost);
        }
      }
    }

    const rows = [];

    for (const [region, costs] of Object.entries(patientCosts)) {
      rows.push({
        costCategory: 'visit_cost',
        region,
        forecasted_total: costs?.reduce(
          (prev: number, next: number) => prev + next,
          0,
        ),
        ...Object.fromEntries(
          [...forecastedMonths.entries()].map(
            ([i, month]: [number, string]) => [month, costs?.[i]],
          ),
        ),
      });
    }

    for (const [region, costs] of Object.entries(adminCosts)) {
      rows.push({
        costCategory: 'admin_cost',
        region,
        forecasted_total: costs?.reduce(
          (prev: number, next: number) => prev + next,
          0,
        ),
        ...Object.fromEntries(
          [...forecastedMonths.entries()].map(
            ([i, month]: [number, string]) => [month, costs?.[i]],
          ),
        ),
      });
    }

    return rows;
  }, [generatedForecast, contractContainerSlug, forecastedMonths]);
}
