import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import type {
  ForecastParameterRow,
  ForecastParameterType,
} from 'shared/lib/types';

import { useGetForecastParametersByForecastQuery } from 'shared/api/rtkq/forecastparameters';
import { useDefaultParametersQuery } from 'shared/api/rtkq/forecasts';
import { useGetCurrentRegionsQuery } from 'shared/api/rtkq/regions';

const GLOBAL_REGION_NAME = 'Global';

const useForecastParamEditorRows = (
  paramType: ForecastParameterType,
): ForecastParameterRow[] => {
  const forecast = useSelector(selectForecast);

  const { currentData: forecastParameters } =
    useGetForecastParametersByForecastQuery(forecast.trace_id);

  const enrollmentForecastParameters = useMemo(
    () =>
      forecastParameters?.filter((parameter) => parameter.type === paramType),
    [forecastParameters, paramType],
  );

  const { currentData: defaultParameters } = useDefaultParametersQuery(
    forecast.trace_id,
  );

  const { currentData: regions = [] } = useGetCurrentRegionsQuery(
    forecast.start_period,
  );

  const uniqueRegionNames = useMemo(
    () =>
      regions
        .map((region) => region.name)
        .filter((name, index, self) => self.indexOf(name) === index)
        .sort((param1, param2) => param1.localeCompare(param2)),
    [regions],
  );

  return useMemo(() => {
    const rowRegionNames = uniqueRegionNames;
    const globalValueExists =
      GLOBAL_REGION_NAME in (defaultParameters?.[paramType] ?? {});
    if (globalValueExists) {
      rowRegionNames.push(GLOBAL_REGION_NAME);
    }
    const rows = rowRegionNames.map((regionName) => {
      const defaultParameter = defaultParameters?.[paramType][regionName];
      const forecastParameter = enrollmentForecastParameters?.find(
        (parameter) => parameter.region_name === regionName,
      );
      return {
        region_name: regionName,
        actual: defaultParameter ?? 0,
        forecast: forecastParameter?.value ?? 0,
        parameter_trace_id: forecastParameter?.trace_id ?? '',
      };
    });

    return cloneDeep(rows);
  }, [
    uniqueRegionNames,
    enrollmentForecastParameters,
    defaultParameters,
    paramType,
  ]);
};

export default useForecastParamEditorRows;
