import CallSplitIcon from '@mui/icons-material/CallSplit';
import Box from '@mui/material/Box';

import type { CustomCellRendererParams } from 'shared/components/ag-grid/types';
import IconButton from 'shared/ui/icon-button/IconButton';

import { handleAddNewDataRows } from '../../helpers/cell';

function AgGridIntegrationsSplitRenderer({
  data,
  api,
  integrationType,
}: CustomCellRendererParams) {
  const handleSplit = () => {
    handleAddNewDataRows(2, api, data.row_id, data.trace_id, integrationType);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'row',
        height: '100%',
      }}
    >
      {data.split === false && data.isParent && (
        <IconButton onClick={handleSplit}>
          <CallSplitIcon sx={{ fontSize: 'medium' }} />
        </IconButton>
      )}
    </Box>
  );
}

export default AgGridIntegrationsSplitRenderer;
