import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import type { Integration } from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';

import GridStep from '../components/GridStep';
import LoadingStep from '../components/LoadingStep';

type Props = {
  integration: Integration;
  setPurchaseOrderListingIntegration: (
    purchaseOrderListingIntegration: boolean,
  ) => void;
};

function PurchaseOrderListingImportWizard({
  integration,
  setPurchaseOrderListingIntegration,
}: Props) {
  return (
    <FullWidthDialog open>
      <Wizard
        stepNames={['Data Import', 'Mapping']}
        title="Import Invoice Listing"
        // TODO(santosh) - Add validation if user wants to close
        // modal without saving the data from integration
        onClose={() => setPurchaseOrderListingIntegration(false)}
      >
        <LoadingStep integration={integration} />
        <GridStep integration={integration} />
      </Wizard>
    </FullWidthDialog>
  );
}

export default PurchaseOrderListingImportWizard;
