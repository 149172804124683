import { useMemo } from 'react';

import type { CellClassParams, NewValueParams } from '@ag-grid-community/core';
import sum from 'lodash/sum';

import { makeEditableIf } from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import type { UnprocessedPOResponse } from 'shared/lib/types';
import { IntegrationType } from 'shared/lib/types';

export default function usePOListingIntegrationColDefs(): CondorColDef[] {
  return useMemo(() => {
    const ignoredCheck = (data: UnprocessedPOResponse) => !data.ignored;
    const studyIdInvalidCheck = (params: CellClassParams) =>
      (params.data.study_id === '' || params.data.study_id === null) &&
      ignoredCheck(params.data);
    const poAmountInvalidCheck = (params: CellClassParams) =>
      (params.data.po_amount === '' ||
        params.data.po_amount === null ||
        params.data.po_amount === 0) &&
      ignoredCheck(params.data);

    return [
      { field: 'trace_id', hide: true, flex: 1 },
      {
        field: 'error',
        headerName: '',
        flex: 0,
        width: 10,
        cellRenderer: 'AgGridIntegrationsErrorRenderer',
      },
      {
        headerName: 'Ignore',
        field: 'ignored',
        flex: 0,
        width: 75,
        resizable: false,
        ...makeEditableIf(({ data }) => data.parent === null),
        cellEditor: 'AgGridCheckboxCellEditor',
        cellRenderer: 'AgGridIntegrationsIgnoreRenderer',
      },
      {
        headerName: 'Split',
        field: 'split',
        resizable: false,
        width: 65,
        flex: 0,
        cellRenderer: 'AgGridIntegrationsSplitRenderer',
        cellRendererParams: {
          integrationType: IntegrationType.PURCHASE_ORDER_SNAPSHOT,
        },
      },
      {
        width: 100,
        headerName: 'Trial ID',
        field: 'study_id',
        flex: 2,
        cellRenderer: 'AgGridIntegrationsTrialIdRenderer',
        cellRendererParams: {
          integrationType: IntegrationType.PURCHASE_ORDER_SNAPSHOT,
        },
        wrapText: true,
        autoHeight: true,
        cellClassRules: {
          'ag-cell-error-default': studyIdInvalidCheck,
          'ag-cell-error-selected': studyIdInvalidCheck,
        },
        ...makeEditableIf(({ data }) => ignoredCheck(data)),
      },
      { headerName: 'PO #', field: 'po_number', flex: 1 },
      {
        headerName: 'PO amount',
        field: 'po_amount',
        valueGetter: 'Number(node.data.po_amount)',
        flex: 1,
        cellRenderer: 'AgGridMoneyCellRenderer',
        cellRendererParams: {
          showEditIcon: true,
        },
        cellClassRules: {
          'ag-cell-error-default': poAmountInvalidCheck,
          'ag-cell-error-selected': poAmountInvalidCheck,
        },
        ...makeEditableIf(
          // Allow PO Amount editing in two conditions:
          // 1. If it is parent and split is False
          // 2. If it is a child and split is true
          // Since above is a XOR, A !== B is a valid check
          ({ data }) => ignoredCheck(data) && data.isParent !== data.split,
        ),
        onCellValueChanged: (params: NewValueParams) => {
          const { data, api } = params;
          const parentTraceId = data.parent;
          const parentRow = api.getRowNode(parentTraceId);
          if (parentRow) {
            // Get list of child PO numbers
            const poAmountsList = api
              .getRenderedNodes()
              .filter((eachNode) => eachNode.data.parent === parentTraceId)
              .map((eachNode) => Number.parseFloat(eachNode.data.po_amount));

            const totalPOAmount = sum(poAmountsList).toFixed(2);

            // Check if there is a zero/NaN in the list
            const invalidDataInPOAmounts = poAmountsList.some(
              (poAmount) => Number.isNaN(poAmount) || poAmount === 0,
            );

            // No error if Total = sum(all elements) and no zero in the list
            // Also .toFixed(2) is needed for precision
            if (
              totalPOAmount ===
                Number.parseFloat(parentRow.data.po_amount).toFixed(2) &&
              !invalidDataInPOAmounts
            ) {
              parentRow.setDataValue('error', false);
            } else {
              parentRow.setDataValue('error', true);
            }
          }
        },
      },
      { headerName: 'Vendor', field: 'vendor', aggFunc: 'totalLabel', flex: 1 },
      { headerName: 'G/L account', field: 'gl_account', flex: 1 },
      { headerName: 'Description', field: 'description', flex: 1 },
      { headerName: 'PO date', field: 'date', flex: 1 },
      { headerName: 'Status', field: 'status', flex: 1 },
      { headerName: 'Currency', field: 'currency', flex: 1 },
    ];
  }, []);
}
