import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import useForecast from 'forecasting/pages/forecasting/hooks/useForecast';
import { createGroups } from 'shared/lib/contract-assumptions/hooks/useRegionsAndAssumptionGroups';
import type {
  AssumptionGroupRequestDisplay,
  RegionListItemType,
  TraceId,
} from 'shared/lib/types';
import { CRUDAction } from 'shared/lib/types';

import { useGetContractAssumptionGroupsByContractVersionQuery } from 'shared/api/rtkq/assumptiongroups';
import {
  useGetClosedPeriodAssumptionGroupsByContractVersionQuery,
  useGetClosedPeriodRegionsByContractVersionQuery,
} from 'shared/api/rtkq/contracts';
import { useGetRegionsByContractVersionQuery } from 'shared/api/rtkq/regions';

export function useForecastRegionsAndAssumptionGroups(
  contractVersionTraceId?: TraceId,
  readOnly = false,
): [RegionListItemType[], AssumptionGroupRequestDisplay[]] {
  const { period: currentForecastPeriod } = useForecast();

  const { currentData: databaseRegions } = useGetRegionsByContractVersionQuery(
    contractVersionTraceId &&
      currentForecastPeriod &&
      !currentForecastPeriod.is_closed
      ? contractVersionTraceId
      : skipToken,
  );
  const { currentData: closedPeriodRegions } =
    useGetClosedPeriodRegionsByContractVersionQuery(
      contractVersionTraceId &&
        currentForecastPeriod &&
        currentForecastPeriod.is_closed
        ? contractVersionTraceId
        : skipToken,
    );

  const { currentData: databaseAssumptionGroups } =
    useGetContractAssumptionGroupsByContractVersionQuery(
      contractVersionTraceId &&
        currentForecastPeriod &&
        !currentForecastPeriod.is_closed
        ? contractVersionTraceId
        : skipToken,
    );

  const { currentData: closedPeriodAssumptionGroups } =
    useGetClosedPeriodAssumptionGroupsByContractVersionQuery(
      contractVersionTraceId &&
        currentForecastPeriod &&
        currentForecastPeriod.is_closed
        ? contractVersionTraceId
        : skipToken,
    );

  const [allRegions, setAllRegions] = useState<RegionListItemType[]>([]);

  const [assumptionGroups, setAssumptionGroups] = useState<
    AssumptionGroupRequestDisplay[]
  >([]);

  const editableRegions = allRegions.filter(
    (region) => region.action !== CRUDAction.DELETE,
  );

  useEffect(() => {
    const contractVersionRegions = currentForecastPeriod?.is_closed
      ? closedPeriodRegions
      : databaseRegions;
    if (contractVersionRegions !== undefined) {
      setAllRegions(
        contractVersionRegions.map((dbRegion) => ({ ...dbRegion, new: false })),
      );
    }
  }, [databaseRegions, closedPeriodRegions, currentForecastPeriod?.is_closed]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: as this examines assumptionGroups, which is what this generates, this will cause an infinite loop if added
  useEffect(() => {
    if (contractVersionTraceId) {
      setAssumptionGroups(
        createGroups(
          contractVersionTraceId,
          readOnly,
          allRegions,
          assumptionGroups,
          currentForecastPeriod?.is_closed
            ? closedPeriodAssumptionGroups
            : databaseAssumptionGroups,
        ),
      );
    }
  }, [
    contractVersionTraceId,
    allRegions,
    databaseAssumptionGroups,
    closedPeriodAssumptionGroups,
  ]);

  return [editableRegions, assumptionGroups];
}
