import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import usePatientCohorts from 'accruals/wizards/visit-schedules/hooks/usePatientCohorts';

import AssociatedVisitsGrid from './AssociatedVisitsGrid';
import AssociatedVisitsCohortsTabs from './AssociatedVisitsCohortsTabs';
import EmptyAssociatedVisits from '../EmptyAssociatedVisits';

function AssociatedVisitsPage() {
  const { patientCohorts, isLoading } = usePatientCohorts(false);
  const { cohortId } = useParams<{
    cohortId: string;
  }>();

  const needToShowEmptyState =
    isLoading ||
    !patientCohorts.length ||
    patientCohorts.some((cohort) => !cohort.patientAssessments.length);

  if (needToShowEmptyState) {
    return <EmptyAssociatedVisits />;
  }

  return (
    <>
      <Typography fontWeight={700} mb={2} variant="body1">
        Please indicate how likely it is a procedure will occur during a given
        visit. This will impact your forecasting calculations.
      </Typography>
      <AssociatedVisitsCohortsTabs />
      {!!cohortId && <AssociatedVisitsGrid cohortId={cohortId} />}
    </>
  );
}

export default AssociatedVisitsPage;
