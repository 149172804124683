import type { GridApi } from '@ag-grid-community/core';
import { v4 as uuid4 } from 'uuid';

import { IntegrationType } from 'shared/lib/types';

const RECORD_SEPARATOR = '@@@@@@';

function getExtraFields(integrationType: IntegrationType | undefined) {
  switch (integrationType) {
    case IntegrationType.PURCHASE_ORDER_SNAPSHOT:
      return {
        po_amount: 0.0,
        study_id: '',
      };
    default:
      return {};
  }
}

export function handleAddNewDataRows(
  count: number,
  api: GridApi,
  currentRowId: string,
  parentTraceId: string,
  integrationType: IntegrationType | undefined,
) {
  const currentRow = api.getRowNode(currentRowId);

  if (currentRow?.rowIndex != null) {
    const newRow = {
      ...currentRow.data,
      error: false,
      isParent: false,
      parent: parentTraceId,
      split: true,
      trace_id: null,
      ...getExtraFields(integrationType),
    };
    const newRows = Array.from({ length: count }, () => ({
      ...newRow,
      row_id: `${currentRow.data.trace_id as string}${RECORD_SEPARATOR}${uuid4()}`,
    }));

    api.applyTransaction({
      add: newRows,
      addIndex: currentRow.rowIndex + 1,
      update: [{ ...currentRow.data, split: true, error: true }],
    });

    api.refreshCells({ rowNodes: [currentRow], force: true });
  }
}
