import { useSelector } from 'react-redux';

import NavigationTabs from 'shared/components/tabs/NavigationTabs';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import { getForecastingTabWithContractContainer } from 'routes';

import { useGetContractContainersWithFiltersQuery } from 'shared/api/rtkq/contractcontainers';

function ContractContainerTabs() {
  const forecast = useSelector(selectForecast);
  const { data: contractContainers } = useGetContractContainersWithFiltersQuery(
    { trial: forecast.trial, vendor_type: 'CRO' },
  );

  const contractContainerTabs =
    contractContainers?.map((container) => ({
      key: getForecastingTabWithContractContainer(
        forecast.trace_id,
        'expenses',
        container.trace_id,
      ),
      label: container.vendor_name ?? container.contract_id,
    })) ?? [];

  contractContainerTabs.push({
    key: getForecastingTabWithContractContainer(
      forecast.trace_id,
      'expenses',
      'occ',
    ),
    label: 'Other Clinical Contracts',
  });

  return <NavigationTabs tabs={contractContainerTabs} />;
}

export default ContractContainerTabs;
