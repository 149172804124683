import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import { ForecastParameterType } from 'shared/lib/types';
import { processForecastParameters } from 'forecasting/pages/forecasting/utils/forecastUtils';

import { useDefaultParametersQuery } from 'shared/api/rtkq/forecasts';

function useForecastSitesRows() {
  const forecast = useSelector(selectForecast);
  const { currentData: defaultParameters } = useDefaultParametersQuery(
    forecast.trace_id,
  );

  return useMemo(
    () =>
      processForecastParameters(
        defaultParameters?.[ForecastParameterType.SITES_RATE],
        'Sites',
      ),
    [defaultParameters],
  );
}

export default useForecastSitesRows;
