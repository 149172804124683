import { type ReactElement, useEffect, useMemo, useRef } from 'react';

import { useParams } from 'react-router-dom';

import type { AgGridRef } from 'shared/components/ag-grid/CondorAgGrid';
import { useCommentsPopup } from 'shared/components/comments/CommentsPopupContext';
import LockGridReminder from 'shared/components/lock-grid/LockGridReminder';
import NavigationTabs from 'shared/components/tabs/NavigationTabs';

import { getSiteLabCostMatrixTab } from 'routes';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import { LockGrid } from 'shared/lib/types';

import type { apiJSON } from 'shared/api/rtkq/apiJSON';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import EditLab from './components/EditLab';
import EditSite from './components/EditSite';
import SiteLabHeader from './components/SiteLabHeader';
import LabGrid from './grids/LabGrid';
import SiteGrid from './grids/SiteGrid';

function SiteLabCostMatrixPage(): ReactElement {
  const { activeTab } = useParams();
  const sx = useMemo(() => ({ height: '100%' }), []);
  const gridRef = useRef<AgGridRef<apiJSON>>(null);
  const { setGridRef } = useCommentsPopup();

  // biome-ignore lint/correctness/useExhaustiveDependencies: we need to update the grid ref when the active tab changes
  useEffect(() => {
    setGridRef(gridRef.current);
  }, [setGridRef, activeTab]);

  const tabContent = activeTab
    ? {
        sites: (
          <>
            <SiteGrid
              ref={gridRef}
              overlayNoRowsTemplate="No sites added."
              sx={sx}
            />
            <LockGridReminder grid={LockGrid.SITE_COST_MATRIX} />
          </>
        ),
        labs: (
          <>
            <LabGrid
              ref={gridRef}
              overlayNoRowsTemplate="No labs added."
              sx={sx}
            />
            <LockGridReminder grid={LockGrid.LAB_COST_MATRIX} />
          </>
        ),
      }[activeTab]
    : undefined;

  return (
    <PageContentLoadingContainer
      tabs={
        <NavigationTabs
          activeTab={activeTab}
          generateUrl={getSiteLabCostMatrixTab}
          tabs={[
            { key: 'sites', label: 'Sites' },
            {
              key: 'labs',
              label: 'Labs',
            },
          ]}
        />
      }
    >
      <FlexGridContainer>
        <SiteLabHeader activeTab={activeTab} gridRef={gridRef.current} />
        <EditSite />
        <EditLab />
        {tabContent}
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(SiteLabCostMatrixPage);
