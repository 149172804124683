import { useMemo } from 'react';

import type {
  ForecastedGridsResponse,
  LineItemForecasting,
} from 'shared/lib/types';

import type { apiJSON } from 'shared/api/rtkq/apiJSON';

function processForecastedExpenseRowData(
  expenseDataRows: apiJSON[] | undefined,
  generatedForecast: ForecastedGridsResponse | undefined,
  forecasted_line_items: Record<string, LineItemForecasting | null> | undefined,
) {
  if (
    forecasted_line_items &&
    expenseDataRows &&
    generatedForecast?.expenses.length
  ) {
    const forecastedMonths = Object.keys(
      generatedForecast.expenses[0] ?? {},
    ).filter((key) => key.startsWith('forecasted_month_'));
    for (const row of expenseDataRows) {
      let forecastedExpense = row.monthlyExpenseValuesTotal ?? 0;
      const lineItemForecast = forecasted_line_items[row.displayRowNum];
      if (lineItemForecast) {
        for (const [index, month] of forecastedMonths.entries()) {
          row[month] = lineItemForecast.forecasts_by_month[index];
          forecastedExpense += row[month] ?? 0;
        }
      }
      row.forecasted_total = forecastedExpense;
    }
  }
  return expenseDataRows;
}

export default function useForecastedExpenseRowData(
  expenseData: apiJSON[] | undefined,
  generatedForecast: ForecastedGridsResponse | undefined,
  forecasted_line_items: Record<string, LineItemForecasting | null> | undefined,
) {
  return useMemo(
    () =>
      processForecastedExpenseRowData(
        expenseData,
        generatedForecast,
        forecasted_line_items,
      ),
    [expenseData, generatedForecast, forecasted_line_items],
  );
}
