import type { ReactElement } from 'react';

import type { SxProps } from '@mui/material/styles';

import type { UserDisplayOptions } from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import useCroExpenseSummaryRows from 'accruals/pages/clinical-expenses/cro/hooks/useCroExpenseSummaryRows';
import useOccExpenseSummaryRows from 'accruals/pages/clinical-expenses/occ/hooks/useOccExpenseSummaryRows';
import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { PeriodGridBlobType } from 'shared/lib/types';

import useClinicalExpenseSummaryColumnDefs from '../hooks/useClinicalExpenseSummaryColumnDefs';
import useClinicalExpenseSummaryGridOptions from '../hooks/useClinicalExpenseSummaryGridOptions';
import useClinicalExpenseSummaryRows from '../hooks/useClinicalExpenseSummaryRows';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
  userDisplayOptions: UserDisplayOptions;
};

function ClinicalExpenseSummaryGrid(props: Props): ReactElement {
  const { overlayNoRowsTemplate, sx, userDisplayOptions } = props;

  const columnDefs = useGridColDefs(useClinicalExpenseSummaryColumnDefs);
  const { rows: croRows, isLoading: isLoadingCro } =
    useCroExpenseSummaryRows('ALL');
  const { rows: occRows, isLoading: isLoadingOcc } = useOccExpenseSummaryRows();
  const rowData = useClinicalExpenseSummaryRows(
    croRows,
    occRows,
    isLoadingCro || isLoadingOcc,
  );
  const gridOptions = useGridOptions(useClinicalExpenseSummaryGridOptions);

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      domLayout="autoHeight"
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
      userDisplayOptions={userDisplayOptions}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  ClinicalExpenseSummaryGrid,
  PeriodGridBlobType.CLINICAL_EXPENSE_SUMMARY,
);
