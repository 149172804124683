import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';

import { type TraceId } from 'shared/lib/types';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';

import useAssociatedVisitsColumnDefs from './useAssociatedVisitsColumnDefs';
import useAssociatedVisitsRows from './useAssociatedVisitsRows';

type Props = {
  cohortId: TraceId;
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
};

function AssociatedVisitsGrid(props: Props) {
  const { cohortId, overlayNoRowsTemplate, sx } = props;
  const columnDefs = useAssociatedVisitsColumnDefs(cohortId);
  const rows = useAssociatedVisitsRows();

  return (
    <FlexGridContainer>
      <CondorAgGrid
        columnDefs={columnDefs}
        domLayout="autoHeight"
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rows}
        sx={sx}
      />
    </FlexGridContainer>
  );
}

export default AssociatedVisitsGrid;
