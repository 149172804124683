import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import GradientCircularProgress from 'shared/components/progress-bar/GradientCircularProgress';

import type { Integration } from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useGetDataFromIntegrationQuery } from 'shared/api/rtkq/integrations';

type Props = {
  integration: Integration;
};

function LoadingStep(props: Props) {
  const { integration } = props;

  const { isLoading } = useGetDataFromIntegrationQuery(integration.trace_id);

  return (
    <WizardStep>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 1,
          width: '100%',
          height: '100%',
        }}
      >
        {isLoading && <GradientCircularProgress />}
        <Typography variant="h4">
          Importing data from {integration.vendor_name}
        </Typography>
      </Box>
    </WizardStep>
  );
}

export default LoadingStep;
