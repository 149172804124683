import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import type { Integration, UnprocessedPOResponse } from 'shared/lib/types';

import { useGetUnprocessedPurchaseOrdersQuery } from 'shared/api/rtkq/integrations';

function processPOListingIntegrationRows(
  poIntegrationGrid?: { rows: UnprocessedPOResponse[] } | undefined,
) {
  return poIntegrationGrid
    ? cloneDeep(
        poIntegrationGrid.rows.map((eachRow) => ({
          ...eachRow,
          isParent: true,
          row_id: eachRow.trace_id,
        })),
      )
    : undefined;
}

export default function usePOListingIntegrationRows(
  integration: Integration,
): UnprocessedPOResponse[] | undefined {
  const { currentData: unprocessedPOData } =
    useGetUnprocessedPurchaseOrdersQuery(integration.trace_id);

  return useMemo(() => {
    if (unprocessedPOData === undefined) {
      return undefined;
    }
    return processPOListingIntegrationRows({ rows: unprocessedPOData });
  }, [unprocessedPOData]);
}
