import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import type { Integration } from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';

import POListingIntegrationGrid from '../grids/POListingIntegrationGrid';

type Props = {
  integration: Integration;
};

function GridStep({ integration }: Props) {
  return (
    <WizardStep disableBackButton>
      <Box
        sx={{
          gap: 1,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '800px',
        }}
      >
        <Typography color={(theme) => theme.palette.primary.main} variant="h6">
          Review the trials we’ve associated to each Invoice #. Reassign, split,
          or ignore lines as needed.
        </Typography>
        <Typography variant="body1">
          In some cases we were not able to identify which trial the line item
          should belong to, or the same PO# is used in multiple trials. You may
          reassign line items or split line items across multiple trials if
          needed. Ignored line items will not be used in any trial.
        </Typography>
        <Box height="5px" />
      </Box>
      <POListingIntegrationGrid
        integration={integration}
        overlayNoRowsTemplate="No purchase orders."
        sx={useMemo(() => ({ height: '100%' }), [])}
      />
    </WizardStep>
  );
}

export default GridStep;
