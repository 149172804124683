import type { Integration, UnprocessedPOResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const PATH = 'integrations';

const apiEndpointFactory =
  constructApi<Integration>(PATH).withTags('INTEGRATIONS');
const api = apiEndpointFactory.inject(() => ({
  getIntegrationsByCompany: apiEndpointFactory.getWithFilters(),
  getUnprocessedPurchaseOrders: apiEndpointFactory.getExtraRoute<
    UnprocessedPOResponse[]
  >('get_unprocessed_purchase_orders'),
  getDataFromIntegration: apiEndpointFactory.getExtraRoute<
    UnprocessedPOResponse[]
  >('new_from_integration'),
}));

export const {
  useGetIntegrationsByCompanyQuery,
  useGetDataFromIntegrationQuery,
  useGetUnprocessedPurchaseOrdersQuery,
} = api;
