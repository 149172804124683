import { isDefaultLabeledParameterResponse } from 'shared/api/rtkq/forecasts';

export function processForecastParameters(
  parameters: Record<string, number> | undefined,
  assumptionPrefix: string,
) {
  const total = Object.values(parameters ?? {}).reduce((acc, value) => {
    if (isDefaultLabeledParameterResponse(value)) {
      return acc + value.value;
    }
    return acc;
  }, 0);

  const rows = Object.entries(parameters ?? {}).map(([region, value]) => {
    const result = {
      assumption: `${assumptionPrefix} ${region}`,
      isTotal: false,
      forecast: 0,
      actual: 0,
    };

    if (isDefaultLabeledParameterResponse(value)) {
      Object.assign(result, {
        actual: value.value,
        forecast: value.value,
        iconTooltipMessage: `Based off ${value.vendor_type} ${value.vendor_name}`,
      });
    }

    return result;
  });

  rows.push({
    assumption: `${assumptionPrefix} global total`,
    actual: total,
    forecast: total,
    isTotal: true,
  });

  return rows;
}
