import { useMemo } from 'react';

import usePatientCohorts from 'accruals/wizards/visit-schedules/hooks/usePatientCohorts';
import type { TraceId } from 'shared/lib/types';

function useAssociatedVisitsColumnDefs(cohortId: TraceId) {
  const { patientCohorts } = usePatientCohorts(false);
  const visits = patientCohorts.find(
    (cohort) => cohort.traceId === cohortId,
  )?.patientAssessments;

  return useMemo(
    () => [
      {
        headerName: 'Procedures',
        width: 300,
        children: [
          {
            headerName: 'Procedure',
            suppressHeaderMenuButton: true,
          },
        ],
      },
      ...[
        ...(visits ?? []).map((visit) => ({
          headerName: visit.name,
          children: [
            {
              headerName: 'Part of visit?',
              width: 100,
              suppressHeaderMenuButton: true,
            },
            {
              headerName: '% occurrence',
              width: 100,
              suppressHeaderMenuButton: true,
            },
          ],
        })),
      ],
    ],
    [cohortId, visits],
  );
}

export default useAssociatedVisitsColumnDefs;
