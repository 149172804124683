import { useMemo, useRef } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { tooltip } from 'shared/components/ag-grid-cells/renderers/ag-grid-custom-cell-renderer/helpers';

import ParametersMenu from 'forecasting/components/parameters-menu/ParametersMenu';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';

import PatientEnrollmentParamsGrid from '../grids/PatientEnrollmentParamsGrid';
import PatientMonthsParamsGrid from '../grids/PatientMonthsParamsGrid';
import PatientsParamsGrid from '../grids/PatientsParamsGrid';
import SiteActivationParamsGrid from '../grids/SiteActivationParamsGrid';
import SiteMonthsParamsGrid from '../grids/SiteMonthsParamsGrid';
import SitesParamsGrid from '../grids/SitesParamsGrid';
import TimelineParamsGrid from '../grids/TimelineParamsGrid';

const TARGET_SCROLL_SELECTOR = 'data-scroll-to-category';

function ParametersTab() {
  const containerRef = useRef<HTMLElement | null>(null);
  const isParametersNewSectionsEnabled = useFeatureFlag(
    'forecasting_parameters_new_sections',
  );

  function onItemClick(index: number) {
    const containerOffset =
      containerRef.current?.getBoundingClientRect().top ?? 0;
    const target = containerRef.current?.querySelectorAll(
      `[${TARGET_SCROLL_SELECTOR}]`,
    )[index];

    if (target) {
      containerRef.current?.scrollTo({
        top: (target as HTMLElement).offsetTop - containerOffset,
        behavior: 'smooth',
      });
    }
  }

  const patientEnrollmentParamsGridSx = useMemo(
    () => ({ mt: 2, height: '250px' }),
    [],
  );
  const siteActivationParamsGridSx = useMemo(
    () => ({ mt: 2, height: '250px' }),
    [],
  );
  const patientsParamsGridSx = useMemo(() => ({ mt: 2, height: '600px' }), []);
  const patientMonthsParamsGridSx = useMemo(
    () => ({ mt: 2, height: '300px' }),
    [],
  );
  const sitesParamsGridSx = useMemo(() => ({ mt: 2, height: '300px' }), []);
  const siteMonthsParamsGridSx = useMemo(
    () => ({ mt: 2, height: '300px' }),
    [],
  );
  const timelineParamsGridSx = useMemo(() => ({ mt: 2, height: '710px' }), []);

  return (
    <>
      <ParametersMenu onItemClick={onItemClick} />
      <Box
        ref={containerRef}
        sx={{
          ml: 3,
          overflowY: 'scroll',
          flex: 1,
        }}
      >
        <Typography
          color="primary.main"
          variant="h4"
          {...{ [TARGET_SCROLL_SELECTOR]: true }}
        >
          Scenario planning
        </Typography>
        <Typography
          mt={4}
          variant="subtitle1"
          {...{ [TARGET_SCROLL_SELECTOR]: true }}
        >
          Patient enrollment
        </Typography>
        <PatientEnrollmentParamsGrid sx={patientEnrollmentParamsGridSx} />
        {isParametersNewSectionsEnabled && (
          <Typography
            height={200}
            mt={4}
            variant="subtitle1"
            {...{ [TARGET_SCROLL_SELECTOR]: true }}
          >
            Patients dropped
          </Typography>
        )}
        <Typography
          mt={4}
          variant="subtitle1"
          {...{ [TARGET_SCROLL_SELECTOR]: true }}
        >
          Site activation
        </Typography>
        <SiteActivationParamsGrid sx={siteActivationParamsGridSx} />
        {isParametersNewSectionsEnabled && (
          <>
            <Typography
              height={200}
              variant="subtitle1"
              {...{ [TARGET_SCROLL_SELECTOR]: true }}
            >
              Trial averages
            </Typography>
            <Typography
              color="primary.main"
              variant="h4"
              {...{ [TARGET_SCROLL_SELECTOR]: true }}
            >
              Contract assumptions{' '}
              {tooltip(
                <InfoOutlinedIcon color="secondary" fontSize="small" />,
                <>
                  Patient and site counts for each region are based off the max
                  count found among all contracts in the trial.
                  <Box mt={1}>
                    Timeline start dates are based off the earliest start date
                    found, and end dates are based off the latest end date found
                    among all contracts.
                  </Box>
                </>,
              )}
            </Typography>
            <Typography
              mt={4}
              variant="subtitle1"
              {...{ [TARGET_SCROLL_SELECTOR]: true }}
            >
              Patients
            </Typography>
            <PatientsParamsGrid sx={patientsParamsGridSx} />
            <Typography
              mt={4}
              variant="subtitle1"
              {...{ [TARGET_SCROLL_SELECTOR]: true }}
            >
              Patient months
            </Typography>
            <PatientMonthsParamsGrid sx={patientMonthsParamsGridSx} />
            <Typography
              mt={4}
              variant="subtitle1"
              {...{ [TARGET_SCROLL_SELECTOR]: true }}
            >
              Sites
            </Typography>
            <SitesParamsGrid sx={sitesParamsGridSx} />
            <Typography
              mt={4}
              variant="subtitle1"
              {...{ [TARGET_SCROLL_SELECTOR]: true }}
            >
              Site months
            </Typography>
            <SiteMonthsParamsGrid sx={siteMonthsParamsGridSx} />
            <Typography
              mt={4}
              variant="subtitle1"
              {...{ [TARGET_SCROLL_SELECTOR]: true }}
            >
              Timeline
            </Typography>
            <TimelineParamsGrid sx={timelineParamsGridSx} />
          </>
        )}
      </Box>
    </>
  );
}

export default ParametersTab;
