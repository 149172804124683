import { type ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import ScheduleOfAssessmentsTabs, {
  SCHEDULE_TABS,
} from './ScheduleOfAssessmentsTabs';
import ScheduleOfAssessmentsProceduresSubTabs, {
  SCHEDULE_SUB_TABS,
} from './ScheduleOfAssessmentsProceduresSubTabs';
import AssociatedVisitsPage from './associated-visits/AssociatedVisitsPage';

function ScheduleOfAssessmentsPage(): ReactElement {
  const { activeTab, activeSubTab } = useParams<{
    activeTab: string;
    activeSubTab: string;
  }>();
  return (
    <PageContentLoadingContainer
      tabs={
        <>
          <ScheduleOfAssessmentsTabs />
          {activeTab === SCHEDULE_TABS.PROCEDURES && (
            <ScheduleOfAssessmentsProceduresSubTabs />
          )}
        </>
      }
    >
      {activeTab === SCHEDULE_TABS.PROCEDURES &&
        activeSubTab === SCHEDULE_SUB_TABS.ASSOCIATED_VISITS && (
          <AssociatedVisitsPage />
        )}
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(ScheduleOfAssessmentsPage);
