import type { SyntheticEvent } from 'react';

import TuneIcon from '@mui/icons-material/Tune';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';

const SCENARIO_PLANNING = [
  'Patient enrollment',
  'Patients dropped',
  'Site activation',
  'Trial averages',
];
const CONTRACT_ASSUMPTIONS = [
  'Patients',
  'Patient Months',
  'Sites',
  'Site months',
  'Timeline',
];

type Props = {
  onItemClick: (index: number) => void;
};

function ParametersMenu(props: Props) {
  const { onItemClick } = props;
  const isParametersNewSectionsEnabled = useFeatureFlag(
    'forecasting_parameters_new_sections',
  );

  function handleItemClick(event: SyntheticEvent) {
    const liElement = (event.target as HTMLElement).closest('li')!;

    const ulElement = liElement.parentElement as HTMLUListElement;
    const liElements = [...ulElement.children].filter(
      (element) => element.tagName === 'LI',
    );

    onItemClick(liElements.indexOf(liElement));
  }

  return (
    <Box
      bgcolor=""
      sx={{
        padding: 2,
        bgcolor: 'common.white',
        borderRadius: 1,
        border: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Typography component="div" pl={2} py={2} variant="overline">
        parameters
      </Typography>

      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={handleItemClick}>
            <ListItemIcon
              sx={{
                minWidth: 4,
                mr: 1.5,
              }}
            >
              <TuneIcon
                sx={{
                  color: 'primary.dark',
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Scenario planning"
              primaryTypographyProps={{
                variant: 'subtitle2',
              }}
            />
          </ListItemButton>
        </ListItem>

        {SCENARIO_PLANNING.filter(
          (text) =>
            isParametersNewSectionsEnabled ||
            text === 'Patient enrollment' ||
            text === 'Site activation',
        ).map((text) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={handleItemClick}>
              <ListItemText
                primary={text}
                primaryTypographyProps={{
                  variant: 'body1',
                  ml: 4.5,
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}

        {isParametersNewSectionsEnabled && (
          <>
            <Divider sx={{ my: 1 }} />
            <ListItem disablePadding>
              <ListItemButton onClick={handleItemClick}>
                <ListItemIcon
                  sx={{
                    minWidth: 4,
                    mr: 1.5,
                  }}
                >
                  <ViewTimelineOutlinedIcon
                    sx={{
                      color: 'primary.dark',
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Contract assumptions"
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                  }}
                />
              </ListItemButton>
            </ListItem>
          </>
        )}

        {isParametersNewSectionsEnabled &&
          CONTRACT_ASSUMPTIONS.map((text) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={handleItemClick}>
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{
                    variant: 'body1',
                    ml: 4.5,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Box>
  );
}

export default ParametersMenu;
