import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import Empty from 'shared/assets/icons/Empty';
import Button from 'shared/ui/button/Button';

import { getScheduleOfAssessmentsTab } from 'routes';

import { SCHEDULE_TABS } from './ScheduleOfAssessmentsTabs';

function EmptyAssociatedVisits() {
  const navigate = useNavigate();

  function onClickManageVisits() {
    navigate(getScheduleOfAssessmentsTab(SCHEDULE_TABS.VISITS));
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        mt: 10,
      }}
    >
      <Box
        sx={{
          width: '500px',
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
        }}
      >
        <Empty sx={{ height: 80, width: 80 }} />
        <Typography mt={2} variant="h6">
          There are no cohorts and/or visits to map your procedures to.
        </Typography>
        <Typography color="secondary" mt={1} variant="body1">
          To configure associated visits, make sure you have at least one cohort
          and visit.
        </Typography>
        <Button
          color="primary"
          sx={{ mt: 4 }}
          testId="btn-manage-visits"
          variant="outlined"
          onClick={onClickManageVisits}
        >
          Manage Visits
        </Button>
      </Box>
    </Box>
  );
}

export default EmptyAssociatedVisits;
