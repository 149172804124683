import { useMemo } from 'react';

import { getTrialMoneyCellConfig } from 'shared/components/ag-grid-cells/config';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';

import { FORECASTED_TOTAL, LTD_EXPENSE, MONTHS } from 'shared/columnHeaders';
import type { ForecastedGridsResponse } from 'shared/lib/types';

import { forecastMonthColDefs } from './forecastMonthColDefs';

function processForecastedExpenseGridColumnDefs(
  _isOpenPeriod: boolean,
  baseColDefs: Array<CondorColDef | CondorColGroupDef> | undefined,
  generatedForecast: ForecastedGridsResponse | undefined,
  showActuals: boolean,
): Array<CondorColDef | CondorColGroupDef> {
  if (!baseColDefs) {
    return [];
  }

  const copyOfBaseColDefs = [...baseColDefs];

  if (!generatedForecast?.expenses.length) {
    return baseColDefs;
  }

  const forecastedMonths = Object.keys(generatedForecast.expenses[0]).filter(
    (key) => key.startsWith('forecasted_month_'),
  );

  const ltdExpenseColumnGroup = copyOfBaseColDefs.find(
    (colDef) => colDef.headerName === LTD_EXPENSE,
  ) as CondorColGroupDef | undefined;

  if (ltdExpenseColumnGroup) {
    const forecastTotalColumn = ltdExpenseColumnGroup.children.find(
      (child) => child.headerName === FORECASTED_TOTAL,
    );
    if (!forecastTotalColumn) {
      ltdExpenseColumnGroup.children.push({
        headerName: FORECASTED_TOTAL,
        field: 'forecasted_total',
        ...getTrialMoneyCellConfig(),
      });
    }
  }

  copyOfBaseColDefs.push({
    headerName: 'Forecasted Monthly Expense',
    children: forecastMonthColDefs(forecastedMonths, true),
  });

  return showActuals
    ? copyOfBaseColDefs.map((colDef) =>
        colDef.headerName === MONTHS
          ? { ...colDef, headerName: 'Actual Monthly Expense' }
          : colDef,
      )
    : copyOfBaseColDefs.filter((colDef) => colDef.headerName !== MONTHS);
}

export default function useForecastedExpenseGridColumnDefs(
  isOpenPeriod: boolean,
  baseColDefs: Array<CondorColDef | CondorColGroupDef> | undefined,
  generatedForecast: ForecastedGridsResponse | undefined,
  showActuals: boolean,
): Array<CondorColDef | CondorColGroupDef> {
  return useMemo(
    () =>
      processForecastedExpenseGridColumnDefs(
        isOpenPeriod,
        baseColDefs,
        generatedForecast,
        showActuals,
      ),
    [isOpenPeriod, baseColDefs, generatedForecast, showActuals],
  );
}
