import type {
  TraceId,
  TrialRegionMetadataResponse,
  TrialRegionResponse,
} from 'shared/lib/types';

import constructApi from './constructApi';
import { CONTRACTS } from './contracts';
import { CONTRACT_REGIONS } from './regions';

const REGIONS = 'TRIAL_REGIONS';
const REGIONS_METADATA = 'TRIAL_REGIONS_METADATA';

const apiEndpointFactory =
  constructApi<TrialRegionResponse>('trialregions').withTags(REGIONS);
export const api = apiEndpointFactory.inject(() => ({
  getRegionsByTrial: apiEndpointFactory.getBy('trial'),
  getRegionsMetadata: apiEndpointFactory.getExtraRoute<
    TrialRegionMetadataResponse[]
  >('regions_metadata', [REGIONS_METADATA]),
  createRegion: apiEndpointFactory.create([REGIONS_METADATA]),
  attachRegionsToContract: apiEndpointFactory.create<{
    trial_regions: TraceId[];
    contract_version: TraceId;
  }>([REGIONS_METADATA, CONTRACTS, CONTRACT_REGIONS], 'attach_to_contract'),
  updateRegion: apiEndpointFactory.update([REGIONS_METADATA]),
}));

export const {
  useGetRegionsByTrialQuery,
  useGetRegionsMetadataQuery,
  useCreateRegionMutation,
  useAttachRegionsToContractMutation,
  useUpdateRegionMutation,
} = api;
