import { useMemo } from 'react';

import type { SetFilterValuesFuncParams } from '@ag-grid-community/core';
import { format } from 'date-fns/format';
import { useSelector } from 'react-redux';

import type { CondorColDef } from 'shared/components/ag-grid/types';

import { formatShortMonthYear } from 'shared/helpers/helpers';
import {
  type IntegrationAuditLogResponse,
  IntegrationDescription,
} from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';

import { mapTrialsToFilterValues } from '../../audit-log/hooks/utils';
import { mapIntegrationsToFilterValues } from './utils';

// Audit log is intentionally not period aware as it would be too much data to save
export default function useIntegrationAuditLogColumnDefs(): Array<
  CondorColDef<IntegrationAuditLogResponse>
> {
  const currentCompany = useSelector(selectCompany);

  const { currentData: trials } = useGetTrialsByCompanyQuery(
    currentCompany.trace_id,
  );

  return useMemo(
    () => [
      {
        colId: 'trial',
        headerName: 'Trial',
        valueGetter: `
          return node.data?.trial ? \`\${node.data.trial.indication} • \${node.data.trial.study_id}\` : '';
        `,
        filter: true,
        filterParams: {
          suppressSorting: true,
          values: (params: SetFilterValuesFuncParams) => {
            if (trials) {
              params.success(mapTrialsToFilterValues(trials));
            }
          },
        },
        suppressHeaderMenuButton: true,
        menuTabs: ['filterMenuTab'],
        width: 300,
      },
      {
        colId: 'is_import',
        headerName: 'Import/export',
        valueGetter: `
          return node.data?.is_import ? 'Import' : 'Export';
        `,
        suppressHeaderMenuButton: true,
        menuTabs: [],
        width: 100,
      },
      {
        colId: 'integration',
        headerName: 'Integration',
        valueGetter: `
          return node.data?.integration ? \`\${node.data.integration.vendor_name}\` : '';
        `,
        suppressHeaderMenuButton: true,
        menuTabs: [],
        flex: 1,
      },
      {
        colId: 'integration_type',
        headerName: 'Type',
        valueGetter: ({ data }) =>
          data?.integration
            ? IntegrationDescription[data.integration.integration_type]
            : '',
        filter: false,
        filterParams: {
          suppressSorting: true,
          values: (params: SetFilterValuesFuncParams) => {
            params.success(mapIntegrationsToFilterValues());
          },
        },
        suppressHeaderMenuButton: true,
        menuTabs: [],
        width: 200,
      },
      {
        colId: 'period',
        headerName: 'Period',
        valueGetter: ({ data }) =>
          data?.period ? formatShortMonthYear(data.period.end_date) : '',
        suppressHeaderMenuButton: true,
        menuTabs: [],
        width: 100,
      },
      {
        colId: 'actor',
        headerName: 'User',
        valueGetter: `
          return node.data?.actor ? \`\${node.data.actor.first_name} \${node.data.actor.last_name}\` : '';
        `,
        suppressHeaderMenuButton: true,
        menuTabs: [],
      },
      {
        colId: 'created_at',
        headerName: 'Last synced',
        valueGetter: ({ data }) =>
          data ? format(data.created_at, 'P p O') : '',
        suppressHeaderMenuButton: true,
        menuTabs: [],
      },
      {
        field: 'status',
        headerName: 'Status code',
        suppressHeaderMenuButton: true,
        cellRenderer: 'AgGridStatusCodeCellRenderer',
        menuTabs: [],
        width: 90,
      },
      {
        field: 'error',
        headerName: 'Status',
        suppressHeaderMenuButton: true,
        menuTabs: [],
        flex: 1,
      },
      {
        field: 'data',
        headerName: 'Data',
        sortable: false,
        suppressHeaderMenuButton: true,
        menuTabs: [],
        cellRenderer: 'AgGridFileDownloadCellRenderer',
        width: 50,
      },
    ],
    [trials],
  );
}
