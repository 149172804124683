import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import type { Integration } from 'shared/lib/types';

import usePOListingIntegrationColDefs from '../hooks/usePOListingIntegrationColDefs';
import usePOListingIntegrationGridOptions from '../hooks/usePOListingIntegrationGridOptions';
import usePOListingIntegrationRows from '../hooks/usePOListingIntegrationRows';

type Props = {
  integration: Integration;
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
};

const defaultColDef: CondorColDef = { sortable: false };

function POListingIntegrationGrid(props: Props) {
  const { integration, overlayNoRowsTemplate, sx } = props;

  const columnDefs = usePOListingIntegrationColDefs();
  const rowData = usePOListingIntegrationRows(integration);
  const gridOptions = useGridOptions(usePOListingIntegrationGridOptions);

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
      suppressClickEdit
    />
  );
}

export default POListingIntegrationGrid;
