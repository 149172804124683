import { useMemo } from 'react';

import type { CondorColDef } from 'shared/components/ag-grid/types';

const useForecastParamViewerColDefs = (groupName: string): CondorColDef[] =>
  useMemo(
    () => [
      {
        field: 'assumption',
        headerName: groupName,
        cellRendererSelector: 'forecastParameterCellRendererSelector',
      },
      {
        field: 'actual',
        headerName: 'Contracted',
        cellDataType: 'string',
      },
      {
        field: 'forecast',
        headerName: 'Forecast',
        cellDataType: 'string',
      },
    ],
    [],
  );

export default useForecastParamViewerColDefs;
