import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Box from '@mui/material/Box';
import sum from 'lodash/sum';

import type { CustomCellRendererParams } from 'shared/components/ag-grid/types';
import IconButton from 'shared/ui/icon-button/IconButton';

import type { UnprocessedPOResponse } from 'shared/lib/types';

import { handleAddNewDataRows } from '../../helpers/cell';

function AgGridIntegrationsTrialIdRenderer(params: CustomCellRendererParams) {
  const { data, value, api, node, column, integrationType } = params;

  const handleEditCell = () => {
    api.startEditingCell({
      rowIndex: node.rowIndex ?? -1,
      colKey: column?.getId() ?? '-1',
    });
  };

  const handleDeleteRow = () => {
    const parentTraceId = data.parent;
    const parentRow = api.getRowNode(data.parent);
    const currentRowToDelete = api.getRowNode(data.row_id);
    const childrenRows = [] as UnprocessedPOResponse[];

    api.forEachNode((eachNode) => {
      if (eachNode.data.parent === parentTraceId) {
        childrenRows.push(eachNode.data);
      }
    });

    const totalPOSum = sum(
      childrenRows.map((row) => Number.parseFloat(row.po_amount)),
    );

    if (currentRowToDelete && parentRow) {
      if (childrenRows.length > 2) {
        const currentRowPOAmount = currentRowToDelete.data.po_amount;

        api.applyTransaction({
          // remove the node being deleted
          remove: [currentRowToDelete.data],

          // Update parent node to show error if totals mismatch
          update: [
            {
              ...parentRow.data,
              // .toFixed(2) is needed as there is a possibility for a floating point error
              error:
                (totalPOSum - currentRowPOAmount).toFixed(2) !==
                  Number.parseFloat(parentRow.data.po_amount).toFixed(2) &&
                childrenRows.length !== 0,
            },
          ],
        });
      } else {
        api.applyTransaction({
          // If there are only two children, remove both of them as removing one
          // would leave only one child, and you always need at least two children
          remove: childrenRows,
          // Clear parent row error as children are handled above
          update: [
            {
              ...parentRow.data,
              split: false,
              error: parentRow.data.study_id === '' || false,
            },
          ],
        });

        api.refreshCells({ rowNodes: [parentRow], force: true });
      }
    }
  };

  const handleAddRow = () => {
    handleAddNewDataRows(1, api, data.row_id, data.trace_id, integrationType);
  };

  let child: React.ReactNode;

  const elementIsParent = data.parent === null || data.parent === undefined;

  if (data.split) {
    if (elementIsParent) {
      child = (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <IconButton onClick={handleAddRow}>
            <AddCircleOutlineOutlinedIcon sx={{ fontSize: 'medium' }} />
          </IconButton>
        </Box>
      );
    } else {
      child = (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <IconButton onClick={handleDeleteRow}>
            <DeleteOutlineOutlinedIcon sx={{ fontSize: 'medium' }} />
          </IconButton>
        </Box>
      );
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <span style={{ width: '80%' }}>{value}</span>
      {child}
      {!data.ignored && (
        <IconButton onClick={handleEditCell}>
          <EditOutlinedIcon sx={{ fontSize: 'medium' }} />
        </IconButton>
      )}
    </Box>
  );
}

export default AgGridIntegrationsTrialIdRenderer;
