import type { ReactElement } from 'react';
import { useCallback } from 'react';

import type { RowClassParams } from '@ag-grid-community/core/dist/types/src/entities/gridOptions';
import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import useForecastParamEditorGridOptions from '../hooks/useForecastParamEditorGridOptions';
import useForecastParamViewerColDefs from '../hooks/useForecastParamViewerColDefs';
import useForecastSiteMonthRows from '../hooks/useForecastSiteMonthRows';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function SiteMonthsParamsGrid(props: Props): ReactElement {
  const { sx, overlayNoRowsTemplate } = props;

  const columnDefs = useForecastParamViewerColDefs('Sites months');
  const gridOptions = useGridOptions(useForecastParamEditorGridOptions);
  const rowData = useForecastSiteMonthRows();

  const getRowClass = useCallback((params: RowClassParams) => {
    if (params.data.isTotal) {
      return 'ag-row-footer';
    }
  }, []);

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      domLayout="autoHeight"
      getRowClass={getRowClass}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
    />
  );
}

export default SiteMonthsParamsGrid;
